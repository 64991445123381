import styled from 'styled-components';

const ComponentWrapper = styled.div `
  align-items: center;
  background-color: #142743;
  display: flex;
  justify-content: space-between;
  .btn.login, .btn.logout {
    background-color: transparent;
    border: .2rem solid white;
    color: white;
    cursor: pointer;
    display: block;
    margin-right: 2rem;
    padding: 1rem 2rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .2s ease-in-out;
    &:hover {
      background-color: white;
      color: #142743;
    }
  }
  img {
    cursor: pointer;
    height: 5rem;
    margin: 1rem 0 1rem 10%;
    max-width: 20rem;
    min-width: 11rem;
    object-fit: contain;
    /* width: 30%; */
  }

`;

export default ComponentWrapper;