import React, { Component } from 'react';
import styled from 'styled-components';
import { injectScript } from '../../helpers/helperFunctions';
import withContext from '../../store/withContext';

var apiEnv = process.env.NODE_ENV === 'development' ? 'https://localhost:3006' : 'https://api.hirezstudios.com';


const ComponentWrapper = styled.div`
  p {
    font-size: 2rem;
    padding: 4rem;
    text-align: center;
    width: 60%;
  }
  .hide {
    display: none;
  }
  .google-login {
    .logout {
      cursor: pointer;
      font-weight: 700;
      text-align: right;
      span {
        color: blue;
        text-decoration: underline;
      }
      @media only screen and (max-width: 400px) {
        font-size: 1.4rem;
      }
    }
  }
  .logout {
    color: #FFF;
    opacity: 1;
    text-decoration: underline;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }
  .google-login {
    margin-right: 1rem;
  }
  .abcRioButtonContents span {
    vertical-align: top;
  }
`

class GoogleLogin extends Component {
  constructor(props) {
		super(props);
		this.state = {
      authenticated: false,
      userEmail: null,
      isAdmin: false,
      name: null
		};
	}

  getURLParam = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
    let regexS = '[\\?&]' + name + '=([^&#]*)';
    let regex = new RegExp(regexS);
    let results = regex.exec(url);
    return results == null ? null : results[1];
  }

  createCookie = (name, value, days) => {
    let expires;
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toGMTString();
    }
    else {
      expires = '';
    }
    document.cookie = name + "=" + value + expires + ";path=/";
  }
  
  readCookie = (name) => {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  logoutGoogle = () => {
    let auth2 = window.gapi.auth2.getAuthInstance();
    auth2.disconnect().then(function () {
      console.log('User signed out.');
      this.props.context.actions.authenticateLogin(false)
      window.location.reload();
    }.bind(this));
  }

  onSignIn = (googleUser) => {
    if (googleUser) {
      const profile = googleUser.getBasicProfile();
      if (profile.getEmail().split('@')[1] === 'hirezstudios.com' || profile.getEmail() === 'gorniak.loc@gmail.com') {
        console.log(profile)
        this.setState({
          userEmail: profile.getEmail()
        });
        this.checkUser();
      }
      else {
        this.logoutGoogle();
        window.alert('You must be a Hi-Rez Employee to log in.');
      }
    }
  }

  onFailure = () => {
    console.log('failed');
    this.props.context.actions.authenticateLogin(false);
  }

  loadGoogle = async () => {
    injectScript('https://apis.google.com/js/platform.js')
    .then(function(data) {
      if (window.gapi.signin2 !== undefined) {
        window.gapi.signin2.render('g-signin2', {
          scope: 'profile email',
          onsuccess: this.onSignIn,
          onfailure: this.onFailure
        });
      }
      else return;
    }.bind(this))
    .then(function(data) {
      if (window.gapi.load !== undefined) {
        window.gapi.load('auth2', function() {
          window.gapi.auth2.init();
          // let auth2 = window.gapi.auth2.getAuthInstance();

          // if (auth2.isSignedIn.get()) {
          //   var profile = auth2.currentUser.get().getBasicProfile();
          // }
        });
      }
      else {
        window.alert('You will need to unblock Google Platform in order to log in. Check your browser settings to see if you are using Ghostery or another browser security extension.');
        return;
      }
    }
    )
  }
  
  checkUser = () => {
    if (this.props.context.authenticateLogin !== true) {
      fetch(`${apiEnv}/hrztools/check-user/${this.state.userEmail}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
        .then(result => {
          if (result.length > 0) {
            this.setState({
              isAdmin: result[0].is_admin,
              name: result[0].name,
              pages: result[0].authorized_pages
            });
            this.state.isAdmin ? this.props.context.actions.makeAdmin(true) : this.props.context.actions.makeAdmin(false);
            this.props.loggedIn(this.props.context.actions.authenticateLogin(true, result[0].authorized_pages, result[0].name));
            document.cookie = `loggedIn=${result[0].name}; expires=0, path=${window.location.href}`;
            let token = this.getURLParam("token");
            if (token || this.readCookie("jwt")) {
              if (token) {
                this.createCookie("jwt", token, 7);
                window.history.pushState('hirez', 'Tools','/');
              }
            }
          } else {
            window.alert('You are not authorized to use this tool.')
          }
        })
    }
  }

  componentDidMount() {
    this.loadGoogle();
  }

  render() {
    return (
      <ComponentWrapper>
        <div className="google-login">
          <div className={'g-signin2 ' + (this.props.context.authenticated === true ? 'hide' : '')} id="g-signin2"></div>
          {this.props.context.authenticated &&
            <div className="logout" onClick={this.logoutGoogle}>Log out as {this.state.name}</div>
          }
        </div>
      </ComponentWrapper>
    )
  }
}

export default withContext(GoogleLogin);