import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './createContext';
// import whitelist from './whitelist.json';

//const apiDomain = process.env.NODE_ENV === 'development' ? 'https://api.hirezstudios.com' : 'https://api.hirezstudios.com';

// The provider, which holds the page-wide store and its actions.
class AppProvider extends Component {

  constructor() {
    super();
    this.state = {
      actions: {
        authenticateLogin: this.authenticateLogin,
        updateRoute: this.updateRoute,
        makeAdmin: this.makeAdmin
      },
      authenticated: false,
      isAdmin: false,
      route: null,
      availableTools: null,
      userName: undefined
    };
  }

  updateRoute = (route) => {
    this.setState({route: route});
  }

  authenticateLogin = (authenticated, pages, name) => {
    if (authenticated) {
      this.setState({
        authenticated: authenticated,
        availableTools: pages,
        userName: name
      });
    }
  }

  makeAdmin = (isAdmin) => {
    this.setState({isAdmin: isAdmin});
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;