import React from 'react';
import { Consumer } from './createContext';

const withContext = (WrappedComponent) => {
	const WithHOC = (props) => {
    return (
      <Consumer>
        {context => <WrappedComponent {...props} context={context} />}
      </Consumer>
    )
	}
	WithHOC.WrappedComponent = WrappedComponent;

	return WithHOC;
}

export default withContext;