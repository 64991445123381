import React, { Component } from 'react';
import GoogleLogin from '../GoogleLogin/GoogleLogin';
import ComponentWrapper from './Header.css';
import withContext from '../../store/withContext';
import { Link } from 'gatsby';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            site: 'Smitegame',
            loggedIn: false,
            route: null,
            redirect: null
        }
    }

    createCookie = (name, value, days) => {
        let expires;
        if (days) {
          let date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = '; expires=' + date.toGMTString();
        }
        else {
          expires = '';
        }
        document.cookie = name + "=" + value + expires + ";path=/";
      }

    readCookie = (name) => {
        let nameEQ = name + '=';
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
          }
          if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
          }
        }
        return null;
      }

      deleteCookie = (name, path) => {
        this.createCookie(name, '', -1, path)
      }

    isLoggedIn = (updateLoggedIn) => {
        this.setState({
            loggedIn: updateLoggedIn
        })
    }

    logOut = () => {
        this.props.context.actions.authenticateLogin(false);
        this.deleteCookie("jwt");
        window.location.reload();
    }

    goHome = () => {
        this.props.context.actions.updateRoute(null);
    }

    componentDidMount() {
      this.setState({
        redirect: window.location.href
      });
      setTimeout(() => {
        let jwt = this.readCookie('jwt');
        if (jwt) {
            this.setState({loggedIn: true});
        }
        else {
            this.setState({loggedIn: false});
        }
      }, 0);
    }

    render() {
        return (
            <ComponentWrapper className="header">
                <Link to="/"><img onClick = { this.goHome } src = "https://webcdn.hirezstudios.com/hirez-studios/wp-content/uploads/2018/10/hirez-logo.png" alt="Hi-Rez Studios" /></Link>
                {this.state.loggedIn === false &&
                    <a className="btn login" href={`https://api.hirezstudios.com/oauth/out/google?action=employee&url=${this.state.redirect}`}>Login</a>
                }
                {this.state.loggedIn === true &&
                    <div onClick={()=>this.logOut()} className="btn logout">Logout</div>
                }
            </ComponentWrapper>
        );
    };
}

export default withContext(Header);